import { dcFactory } from 'dc';
import AsdSelfServiceControls from './js/asd-self-service-controls.component';
import './scss/index.scss';

dcFactory.register(AsdSelfServiceControls);

import ProviderPaginationComponent from './js/provider-pagination.component';
import './js/provider-disclaimer.component';

dcFactory.register(ProviderPaginationComponent);
