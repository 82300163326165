import { BaseComponent } from 'project/general';

class MapWidgetComponent extends BaseComponent {
    static getNamespace() {
        return 'map-widget';
    }

    onInit() {

        var infoWindow = ""; // Contains the pop-up that appears when a marker is clicked
        var mapElement = this.element;



        // https://www.npmjs.com/package/load-google-maps-api#loadgooglemapsapioptions
        const loadGoogleMapsApi = require('load-google-maps-api')

        const apiKey = mapElement.getAttribute("data-dc-map-widget-api_key");

        //  Load the google map scripts
        loadGoogleMapsApi({ key: apiKey }).then(function (googleMaps) {

            //  Gets the data from the javascript variable created by the widget
            var mapData = eval(mapElement.getAttribute("data-dc-map-widget-data-variable-name"));

            //  Map bounds auto-centres the map when multiple locations are being shown
            var mapBounds = new google.maps.LatLngBounds();

            ////  Get the icon SVG
            //var xlinkElement = document.getElementById("icon-NAS_Map_Pin")
            ////var svgIconUrl = xlinkElement.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
            //var iconUrl = xlinkElement.textContent;
            //const svgIconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconUrl)}`;

            //  Create the map element
            var map = new googleMaps.Map(mapElement, {
                zoom: 11,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP]
                }
            })

            //  Add the markers
            for (var i = 0; i < mapData.length; i++) {

                //  Create a marker
                var dataItem = mapData[i];

                var latitude = "";
                if (dataItem.Latitude != undefined) {
                    latitude = dataItem.Latitude;
                }
                else if (dataItem.LocatedAddress != undefined) {
                    latitude = dataItem.LocatedAddress.Latitude
                }

                var longitude = "";
                if (dataItem.Longitude != undefined) {
                    longitude = dataItem.Longitude;
                }
                else if (dataItem.LocatedAddress != undefined) {
                    longitude = dataItem.LocatedAddress.Longitude
                }

                if (dataItem.InfoWindowContent == undefined && dataItem.AddressBlock != undefined) {
                    dataItem.InfoWindowContent = dataItem.AddressBlock;
                }

                if (latitude != "" && longitude != "") {

                    //  Create a marker
                    var latLong = { lat: latitude, lng: longitude };
                    var marker = new google.maps.Marker({
                        position: latLong,
                        map: map,
                        NodeGuid: dataItem.NodeGuid,
                        InfoWindowContent: dataItem.InfoWindowContent,
                        icon: {
                            url: '/content/assets/general/img/NAS_Map_Pin.svg',
                            scaledSize: new google.maps.Size(40, 40),
                        }
                    });
                    //  Extend the map bounds for auto centering
                    mapBounds.extend(latLong);

                    //  Add marker click handler
                    google.maps.event.addListener(marker, 'click', function () {

                        //  Raise a map-marker-clicked event and expose the selected node guid
                        var event = document.createEvent("HTMLEvents");
                        event.initEvent("map-marker-clicked", true, true);
                        event.eventName = "map-marker-clicked";
                        event.NodeGuid = this.NodeGuid;
                        document.dispatchEvent(event);

                        //  Create an Info window where content has been provided
                        if (dataItem.InfoWindowContent != null) {
                            if (infoWindow != '') {
                                infoWindow.close();
                            }
                            infoWindow = new google.maps.InfoWindow({
                                content: this.InfoWindowContent,
                                position: this.position
                            });
                            infoWindow.open(map);
                        }

                    });

                }




            }

            //  Set the map centre
            if (mapData.length == 1) {
                map.panTo(latLong);
            }
            else {
                map.fitBounds(mapBounds);
                map.panToBounds(mapBounds);
            }

        }).catch(function (error) {
            console.error(error)
        })

    }


}

export default MapWidgetComponent;
