$(window).on("load resize", function (e) {
    var disclaimerText = document.querySelector('.provider__disclaimer--search');
    var filterBar = document.querySelector('.filter-bar');
    var mobileLocation = document.querySelector('.results__paging');
    if (disclaimerText) {
        if (document.body.clientWidth > 750 && filterBar != undefined) {
            filterBar.appendChild(disclaimerText);
        }

        else if (mobileLocation != undefined) {
            mobileLocation.before(disclaimerText);
        }
    }

});
