import React from 'react';
import { Fragment } from 'preact';
export class AsdSortDropdown extends React.Component {

    onChangeCapture = (sender) => {

        if (sender != null && sender.target != null) {

            //  this drop down may be in two places:
            const dropdowns = document.getElementsByClassName(this.props.sortType);
            if (dropdowns.length > 1) {
                for (var i = 0; i < dropdowns.length; i++) {
                    var dropdown = dropdowns.item(i);
                    if (dropdown.value != sender.target.value) {
                        dropdown.value = sender.target.value;
                    }
                }
            }

            this.props.onSort(sender);
        }
    }

    render() {

        return (
            <div class="sort-by">
                <select class={this.props.sortType} onChangeCapture={this.onChangeCapture}>
                    {
                        this.props.sortOptions.map((option => {

                            if (option.selected) {
                                return (<option label={option.text} value={option.value} selected>{option.text}</option>)
                            }
                            else {
                                return (<option label={option.text} value={option.value}>{option.text}</option>)
                            }
                            
                        }))
                    }
                </select>
            </div>
        );
    }
}
