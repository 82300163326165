import React from 'react';
import { render, Component } from 'preact';

export class DropdownFilter extends Component {

    state = {
        selectedOptions: [],
        inputValue: this.props.filterValue,
    };

    constructor(...args) {
        super(...args);
        this.filterWrapper = React.createRef();
    }

    toggleOptions = (e) => {
        e.stopPropagation();
        this.props.onToggle(this.props.id);
    };

    componentDidMount() {

        if (this.props.filter.allowMultiple && Array.isArray(this.state.inputValue)) {
            //  append the filter values to selected options state
            var selectedOptions = this.getOptions(this.state.inputValue);
            if (selectedOptions != null) {
                this.state.selectedOptions.push(...selectedOptions)
            }
        }
        else {
            //  append the filter value to selected options state
            var selectedOption = this.getOption(this.state.inputValue);
            if (selectedOption != null) {
                this.state.selectedOptions.push(selectedOption)
            }
        }

        this.setState({
            selectedOptions: this.state.selectedOptions
        })

    }

    isOptionSelected = (option) => {
        for (var i = 0; i < this.state.selectedOptions.length; i++) {
            var thisOption = this.state.selectedOptions[i];
            if (thisOption.value == option.value) {
                return true;
            }
        }
        return false;
    }

    //  Takes a value and returns a matching filter option, or null
    getOption = (valueToFind) => {
        for (var i = 0; i < this.props.filter.options.length; i++) {
            var thisOption = this.props.filter.options[i];
            if (thisOption.value == valueToFind) {
                return thisOption;
            }
        }
        return null;
    }

    //  Takes an array of values and returns any matching filter options, or null
    getOptions = (valuesToFind) => {
        var output = null;
        if (Array.isArray(valuesToFind)) {
            for (var i = 0; i < this.props.filter.options.length; i++) {
                var thisOption = this.props.filter.options[i];
                for (var x = 0; x < valuesToFind.length; x++) {
                    if (thisOption.value == valuesToFind[x]) {
                        if (output == null) {
                            output = [];
                        }
                        output.push(thisOption);
                    }
                }
            }
        }
        else {
            console.error("the given value was not an array.");
        }
        return output;
    }

    //  if the option label is over-length truncate it, else just return the label
    getSelectedOptionLabel() {
        if (this.state.selectedOptions != null && this.state.selectedOptions.length == 1) {
            var selectedOption = this.state.selectedOptions[0];
            if (selectedOption != null && selectedOption.label != null) {
                var label = selectedOption.label;
                var textLimit = 30;
                return (label.length <= textLimit) ? label : label.substring(0, textLimit) + "..";
            }
        }
        return "";
    }

    selectOption = (option) => {
        let selectedOptions = [...this.state.selectedOptions];

        if (this.props.filter.allowMultiple) {
            if (this.isOptionSelected(option)) {
                selectedOptions = selectedOptions.filter(selectedOption => selectedOption.value !== option.value);
            }
            else {
                selectedOptions.push(option);
            }
        } else {
            selectedOptions = [option];
        }

        this.setState({
            selectedOptions,
            inputValue: selectedOptions.map(val => option.value).join(', '),
        });

        if (!this.props.filter.allowMultiple) {
            this.props.onToggle(null);
        }
    };

    clearSelection = () => {
        this.setState({
            selectedOptions: [],
            inputValue: '',
        });
    };

    onDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (!this.filterWrapper.current.contains(e.target)) {
            this.props.onClickOutside();
        }
    }

    registerClickOutsideEventHandler() {
        if (this.props.isOptionsVisible) {
            document.addEventListener('click', this.handleClickOutside);
        }
        else {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    render() {
        this.registerClickOutsideEventHandler();
        var selectedOptions  = this.state.selectedOptions;
        const openButtonIconClass = "open-options-button__icon" + (this.props.isOptionsVisible ? " active" : "");
        return (
            <div ref={this.filterWrapper} class="filter-wrapper" >
                <div class="filter-name">{this.props.filter.label}</div>
                <div className="dropdown-component">

                    {(selectedOptions.length > 1) && (
                        selectedOptions.map((option, index) => (
                            <input
                                type="hidden"
                                name={this.props.filter.name}
                                value={option.value}
                            />
                        ))
                    )}
                    {(selectedOptions.length == 1) && (
                        <input
                            type="hidden"
                            name={this.props.filter.name}
                            value={selectedOptions[0].value}
                        />
                    )}

                    <div className="selection-value" onClick={this.toggleOptions}>
                        {selectedOptions.length == 0 ? "" : (selectedOptions.length == 1 ? this.getSelectedOptionLabel() : selectedOptions.length + " selected")}
                    </div>
                    {this.props.isOptionsVisible && (
                        <div className="dropdown-component__options">
                            {this.props.filter.options.map((option, index) => (
                                <div
                                    key={index}
                                    className={`dropdown-component__options__option ${this.isOptionSelected(option) ? 'option-selected' : ''}`}
                                    data-option-value={option.value}
                                    onClick={() => this.selectOption(option)}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    )}
                    <div class="button-zones">
                        <div className="zone1">
                            {(selectedOptions.length > 0 && !this.props.isOptionsVisible) && (
                                <button type="button" onClick={this.clearSelection} className="button-zone-button clear-selection-button">
                                    <svg aria-hidden="true" className="clear-selection-button__icon">
                                        <use xlinkHref="#icon-cross"></use>
                                    </svg>
                                </button>
                            )}
                        </div>
                        {this.props.filter.button && (
                            <div className="zone2">
                                <button id="open-options-button" type="button" className="button-zone-button open-options-button" onClick={this.toggleOptions}>
                                    <svg aria-hidden="true" className={openButtonIconClass}>
                                        <use xlinkHref="#icon-long-right"></use>
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
