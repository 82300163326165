import React  from 'react';
import { render, Fragment } from 'preact';
import { DropdownFilter } from "./dropdown-filter.jsx";
import { TextFilter } from "./text-filter.jsx";
export class SearchFilters extends React.Component {

    state = {
        activeSelectId: null,
        filters: this.props.filters,
        isExpanded: false
    }

    filterControls = [];

    toggleSelect(id) {
        var activeSelectId = id === this.state.activeSelectId ? null : id;
        this.setState({
            activeSelectId: activeSelectId
        });
    }

    handleClickOutside = () => {
        this.setState({
            activeSelectId: null
        });
    }

    clearFilters = () => {
        if (Array.isArray(this.filterControls)) {
            this.filterControls.map((filterControl) => {
                if (filterControl != null && filterControl.clearSelection != undefined) {
                    filterControl.clearSelection();
                }
            });
        }
        if (this.props.onClearFilters) {
            this.props.onClearFilters();
        }
    }

    submitClicked = () => {
        if (this.props.onSubmitClicked) {
            this.props.onSubmitClicked();
        }
    }

    onToggleAccordion = () => {
        var isExpanded = !this.state.isExpanded;
        this.setState({
            isExpanded: isExpanded
        });
    }

    render() {
        const filters = this.state.filters;
        this.filterControls = [];
        const filterToggleIconClass = "filter-toggle-button__icon" + (this.state.isExpanded ? " active" : "");
        const filterContainerCssClass = "search-filter-component-container" + (this.state.isExpanded ? "" : "_hidden");
        return (
            <div class="search-filter-controls">

                <div class="filter-header">
                    <div class="filter-toggle-button" onClick={this.onToggleAccordion}>
                        FILTER
                        <svg aria-hidden="true" className={filterToggleIconClass}>
                            <use xlinkHref="#icon-long-right"></use>
                        </svg>
                    </div>
                    {
                        (this.state.isExpanded) &&
                        <div id="btnclearallfilters" style="cursor:pointer;width:120px;" onClickCapture={this.clearFilters}>CLEAR FILTERS</div>
                    }
                </div>

                <div className={filterContainerCssClass}>
                    {filters.map((filter, index) => (
                        <Fragment>
                            {
                                (filter.type != "text") &&
                                <DropdownFilter
                                    ref={filterControl => this.filterControls.push(filterControl)}
                                    key={index}
                                    id={index}
                                    filter={filter}
                                    filterValue={filter.value}
                                    isOptionsVisible={this.state.activeSelectId === index}
                                    onToggle={(id) => this.toggleSelect(id)}
                                    onClickOutside={this.handleClickOutside}
                                />
                            }
                            {
                                (filter.type == "text") &&
                                <TextFilter
                                    ref={filterControl => this.filterControls.push(filterControl)}
                                    key={index}
                                    id={index}
                                    filter={filter}
                                    filterValue={filter.value}
                                />
                            }
                        </Fragment>
                    ))}
                    <button class="search-button" style="margin-top:32px;" onClickCapture={this.submitClicked}>
                        SEARCH
                    </button>
                </div>
            </div>
        );
    }
}

