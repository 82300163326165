import React from 'react';
import { render, Component } from 'preact';

export class TextFilter extends React.Component {

    state = {
        selectedValue: '',
        inputValue: this.props.filterValue,
    };


    onValueChanged = (event) => {
        var value = event.target.value;
        this.setState({
            selectedValue: value,
            inputValue: value,
        });
    }

    clearSelection = () => {
        this.setState({
            selectedValue: '',
            inputValue: '',
        });
    };

    render() {

        const { selectedValue } = this.state;
        return (
            <div class="filter-wrapper">
                <div class="filter-name">{this.props.filter.label}</div>
                <div className="text-component">
                    <input
                        type="text"
                        name={this.props.filter.name}
                        onChangeCapture={this.onValueChanged}
                        value={this.state.inputValue}
                    />
                </div>
            </div>
        );
    }
}
