import React from 'react';
import { h, render, Component } from 'preact';
import { BaseComponent } from "project/general";

class SelectionOptionsComponent extends BaseComponent {

    static getNamespace() {
        return 'selection-options';
    }

    static getRequiredRefs() {
        return ['data'];
    }

    onInit() {
        const items = JSON.parse(this.refs.data.innerHTML);
        render(

            <div class="service-grid">
                {items.map((item, index) => (
                    <div key={index}>
                        <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            name={item.name}
                            value={item.value}
                            style={{ display: 'none' }}
                        />
                        <label className="service-tile" htmlFor={`checkbox-${index}`}>
                            <svg>
                                <use xlinkHref={`#icon-${item.svg}`}></use>
                            </svg>
                            <span>{item.title}</span>
                        </label>
                    </div>
                ))}
            </div>,
            this.element
        );
    }
}

export default SelectionOptionsComponent;
