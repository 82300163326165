import React from 'react';
import { Fragment } from 'preact';
export class AsdSearchResultCard extends React.Component {

    viewOnMapClicked = () => {

        if (this.props.onViewOnMapClicked) {
            this.props.onViewOnMapClicked(this.props.latitude, this.props.longitude);
        }
    }

    render() {
        const pageUrl = this.props.pageUrl;
        const title = this.props.title;
        const description = this.props.description;
        const categories = this.props.categories;
        const latitude = this.props.latitude;
        const longitude = this.props.longitude;
        const address = this.props.address;
        const distanceText = this.props.distanceText;

        if (latitude || longitude || address || distanceText) {

            return (
                <div className="results__item results__item--service">
                    <div className="results__content">
                        <a href={pageUrl} title={title} target="_blank" data-dc-gtm={'{"tracking": ["", "Click", " >' + title + ']}'}>
                            <h2 className="results__title">{title}</h2>
                            <div>
                            </div>
                            <div className="results__button">
                                <svg aria-hidden="true" className="service-card__arrow">
                                    <use xlinkHref="#icon-right"></use>
                                </svg>
                            </div>
                        </a>
                        {
                            address &&
                            <h4 className="results__location">
                                {address}
                            </h4>
                        }
                        {
                            distanceText &&
                            <h4 className="results__location">
                                <svg>
                                    <use xlinkHref="#icon-NAS_Map_Pin"></use>
                                </svg>
                                {distanceText}
                                {
                                    latitude && longitude &&
                                    <span>&nbsp;&#40;<a href="#map" class="map__link" onClickCapture={this.viewOnMapClicked}>View on map</a>&#41;</span>
                                }
                            </h4>
                        }
                        <p className="results__description">{description}</p>
                        {
                            categories && categories.length > 0 &&
                            <p style="color: #4203bf; font-size: 14px; padding-top:10px; font-weight: 500;" className="results__categories"><strong>Service:</strong> {categories.join(', ')}</p>
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="results__item results__item--service">
                    <div className="results__content">
                        <a href={pageUrl} title={title} target="_blank" data-dc-gtm={'{"tracking": ["", "Click", " >' + title + ']}'}>
                            <h2 className="results__title">{title}</h2>
                            <div>
                            </div>
                            <div className="results__button">
                                <svg aria-hidden="true" className="service-card__arrow">
                                    <use xlinkHref="#icon-right"></use>
                                </svg>
                            </div>
                            <p className="results__description">{description}</p>
                            {
                                categories && categories.length > 0 &&
                                <p style="color: #4203bf; font-size: 14px; padding-top:10px; font-weight: 500;" className="results__categories"><strong>Service:</strong> {categories.join(', ')}</p>
                            }
                        </a>
                    </div>
                </div>
            );
        }
    }
}


