import React from 'react'
import { render } from "preact";
import { BaseComponent } from "../../../project/general";
import { BaseForm, formConstants } from 'shared/base-form';
import { notificationTypes } from 'project/general';
import { notificationService } from 'project/services';
import { AsdSearchResultsMap } from './asd-search-results-map';
import { AsdSearchResultCard } from './asd-search-result-card';
import { AsdSortDropdown } from './asd-sort-dropdown';
import { SearchFilters } from './filtering/search-filters.jsx';

const PAGE_SIZE = 10;
const SEARCH_TYPE_ALL = 'all';
const SEARCH_TYPE_MAIN = 'main';
const SEARCH_TYPE_ONLINE = 'online';

export default class ASDSearchComponent extends BaseComponent {

    static getNamespace() {
        return 'asd-search-component';
    }

    static getRequiredRefs() {
        return [
            'search_filters_form',
            'filter_definitions',
            'filter_placeholder',
            'tabs_container',
            'map_tab',
            'map_tab_link',
            'main_list_tab',
            'main_list_tab_link',
            'map_results_message_container',
            'map_results_outer_map_container',
            'map_results_toolbar_container',
            'map_results_map_container',
            'map_results_footer_container',
            'main_list_results_toolbar_container',
            'main_list_results_list_container',
            'main_list_results_footer_container',
            'online_results_toolbar_container',
            'online_results_list_container',
            'online_results_footer_container',
            'no_results_content_location',
            'no_results_content_nonlocation',
            'no_results_content_online',
            // State fields
            'pageSize_field',
            'searchType_field',
            'latitude_field',
            'longitude_field',
            'geocodedPostcode_field',
            'geocodeError_field',
            'sort_field',
            'onlineSort_field',
            'skip_field',
            'onlineSkip_field'
        ];
    }

    onInit() {
        this.filtersData = JSON.parse(this.refs.filter_definitions.innerHTML);

        this.populateFormFromUrl(new URLSearchParams(window.location.search));

        this.renderFilters();

        this.refs.pageSize_field.value = PAGE_SIZE;

        this.addListener(this.refs.map_tab_link, 'click', (e) => {
            if (!this.mapComponent) {
                return;
            }

            this.mapComponent.zoomToLocations();
        });

        this.formInstance = new BaseForm(this.refs.search_filters_form, {
            submitElement: this.element.querySelector('button[type="button"]'),
            errorsSummary: this.element.querySelector('[data-errors-summary]'),
            onBeforeSubmit: () => {
                
                const searchType = this.refs.searchType_field.value;

                if (!searchType || searchType === SEARCH_TYPE_ALL) {

                    this.refs.skip_field.value = '';
                    this.refs.onlineSkip_field.value = '';
                }
            },
            onSuccessfulSubmit: (res) => {

                if (res.data.success && res.data.data) {

                    switch (this.refs.searchType_field.value) {

                        case SEARCH_TYPE_ONLINE:
                            if (res.data.data.onlineResults) {
                                this.renderOnlineResults(res.data.data.onlineResults);
                            }
                            else {
                                this.displayError();
                            }
                            break;

                        case SEARCH_TYPE_MAIN:
                            if (res.data.data.mainResults) {
                                this.handleMainSearch(res.data.data.mainResults);
                            }
                            else {
                                this.displayError();
                            }
                            break;

                        case SEARCH_TYPE_ALL:
                            if (res.data.data.mainResults && res.data.data.onlineResults) {
                                this.handleMainSearch(res.data.data.mainResults);
                                this.renderOnlineResults(res.data.data.onlineResults);
                            }
                            else {
                                this.displayError();
                            }
                            break;

                        default:
                            this.displayError();
                    }

                    // Ensure tabs are displayed
                    this.refs.tabs_container.classList.remove('hidden');

                    this.populateUrlFromForm();
                }
                else {
                    this.displayError(res.data.errors && res.data.errors.length ? res.data.errors[0] : res.data.message);
                }
            },
            onFailedSubmit: (error) => {
                this.displayError();
                console.error(error);
            },
            errorsSummaryTemplate: (errors) => {
                return `<div class="alert alert-danger" role="alert">${errors.map((error) => `<div>${error}</div>`).join()}</div>`;
            },
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="field-validation-error" aria-live="assertive" aria-atomic="true"></div>',
                errorTemplate: '<div></div>',
            },
            enctype: formConstants.ENCTYPE_JSON
        });

        this.onSubmitClick(SEARCH_TYPE_ALL);
    }

    //  submits the form - results are processed via this.formInstance.onSuccessfulSubmit
    onSubmitClick = (searchType) => {

        if (!searchType) {

            searchType = SEARCH_TYPE_ALL;
        }

        this.refs.searchType_field.value = searchType

        this.formInstance.onSubmit();
    }

    //  fires when the button to apply filters is clicked (also defined as the submitElement of the form instance)
    onFormSubmitElementClick = () => {
        this.refs.searchType_field.value = SEARCH_TYPE_ALL;
    }

    //  also clears sort values. NB. skip values are automatically cleared when search type is all
    onClearFiltersClick = () => {

        this.refs.sort_field.value = '';
        this.refs.onlineSort_field.value = '';

        this.onSubmitClick(SEARCH_TYPE_ALL);
    }

    //  increments the relevant form value by the page size and loads the results
    doSkip = (field, searchType) => {
        var value = parseInt(field.value);
        if (isNaN(value) || value == undefined) {
            value = 0;
        }  
        field.value = (value + PAGE_SIZE);
        this.onSubmitClick(searchType);
    }

    //  load the next page of location based results
    onMoreResultsClick = () => {
        this.doSkip(this.refs.skip_field, SEARCH_TYPE_MAIN);
    }

    //  load the next page of online-only results
    onMoreOnlineResultsClick = () => {
        this.doSkip(this.refs.onlineSkip_field, SEARCH_TYPE_ONLINE);
    }

    //  copies the value from the event target to the location based sort form value and loads the results
    onSortChanged = (sender) => {
        
        var newValue = sender.target.value;
        this.refs.sort_field.value = newValue;

        this.refs.skip_field.value = '';

        this.onSubmitClick(SEARCH_TYPE_MAIN);
    }

    //  copies the value from the event target to the online sort form value and loads the results
    onOnlineSortChanged = (sender) => {

        var newValue = sender.target.value;
        this.refs.onlineSort_field.value = newValue;

        this.refs.onlineSkip_field.value = '';

        this.onSubmitClick(SEARCH_TYPE_ONLINE);
    }

    onListResultViewOnMapClick = (latitude, longitude) => {
        
        if (!this.mapComponent) {
            return;
        }

        this.refs.map_tab_link.click();
        this.mapComponent.openLocation(latitude, longitude);
    }

    displayError(message) {
        notificationService.push({
            message: message || 'Sorry, something went wrong. Please try again',
            type: notificationTypes.ERROR,
        });
    }

    //  copies url parameter values to relevant filter controls (matches parameter name to input name)
    populateFormFromUrl(queryParams) {

        const form = this.refs.search_filters_form;
        if (!form) return;

        // check the URL parameters and ..
        queryParams.forEach((value, key) => {

            //  populate the static form fields
            const input = form.querySelector('input[name="' + key + '"]');

            if (input && !input.hasAttribute('data-exclude-url')) {
                input.value = value;
            }
            else {
                //  append relevant values to the filters array
                this.filtersData.filters.forEach((filter) => {
                    if (filter.name == key) {
                        if (filter.allowMultiple) {
                            if (!Array.isArray(filter.value)) {
                                filter.value = [value];
                            }
                            else {
                                filter.value.push(value);
                            }
                        }
                        else {
                            filter.value = value;
                        }
                    }
                });
            }
        });
    }

    //  copies non-empty form values to the url parameters (matches parameter name to input name)
    populateUrlFromForm() {

        const formData = new FormData(this.refs.search_filters_form);
        const queryParams = new URLSearchParams(formData);

        //  detect any empty items
        const emptyItems = [];
        queryParams.forEach((value, key) => {
            if (value == '') {
                emptyItems.push(key);
            }
        });

        //  remove empty items
        emptyItems.forEach((key) => {
            queryParams.delete(key, '');
        });

        // remove any values added for inputs marked with data-exclude-url
        this.refs.search_filters_form.querySelectorAll('[data-exclude-url]').forEach((input) => {
            queryParams.delete(input.getAttribute('name'), input.value);
        });

        //  update the url
        const newUrl = `${window.location.pathname}?${queryParams}`;
        window.history.pushState({}, '', newUrl);
    }

    renderFilters() {
        render(
            <SearchFilters onClearFilters={this.onClearFiltersClick} onSubmitClicked={this.onFormSubmitElementClick} filters={this.filtersData.filters} />
            ,
            this.refs.filter_placeholder
        );
    }

    handleMainSearch(searchData) {

        if (searchData.clearResults && this.refs.searchType_field.value === SEARCH_TYPE_ALL) {
            
            // Update values of hidden main search state fields in the form
            this.refs.latitude_field.value = searchData.state.latitude;
            this.refs.longitude_field.value = searchData.state.longitude;
            this.refs.geocodedPostcode_field.value = searchData.state.geocodedPostcode;
            this.refs.geocodeError_field.value = searchData.state.geocodeError;

            if (searchData.state.postcode) {

                this.refs.map_tab.classList.remove('hidden');

                this.refs.main_list_tab.firstChild.nextSibling.innerHTML = 'List View (Local)';

                this.renderMainMapResults(searchData);
                this.renderMainListResults(searchData);

                if (!this.currentSearchIsLocal || !this.searchInitialised) {
                    // Simulate clicking the map tab to activate it
                    this.refs.map_tab_link.click();
                }

                this.currentSearchIsLocal = true;
            }
            else {
                this.refs.map_tab.classList.add('hidden');

                this.refs.main_list_tab.firstChild.nextSibling.innerHTML = 'List View (All)';

                this.renderMainListResults(searchData);

                if (this.currentSearchIsLocal || !this.searchInitialised) {
                    // Simulate clicking the main list tab to activate it
                    this.refs.main_list_tab_link.click();
                }

                this.currentSearchIsLocal = false;
            }

            this.searchInitialised = true;
        }
        else {

            if (searchData.state.postcode) {

                this.renderMainMapResults(searchData);
                this.renderMainListResults(searchData);
            }
            else {
                this.renderMainListResults(searchData);
            }
        }
    }

    renderMainMapResults(searchData) {

        if (searchData.clearResults) {

            this.refs.map_results_toolbar_container.innerHTML = '';
            this.refs.map_results_footer_container.innerHTML = '';

            if (searchData.hasResults) {

                if (searchData.hasSortOptions) {

                    render(
                        <AsdSortDropdown sortType="sort" sortOptions={searchData.sortOptions} onSort={this.onSortChanged} />
                        ,
                        this.refs.map_results_toolbar_container
                    );
                }

                // Hide the message container
                this.refs.map_results_message_container.classList.add('hidden');

                if (!this.mapComponent) {

                    render(
                        <AsdSearchResultsMap
                            ref={(c) => this.mapComponent = c}
                            id="asd_search_results_map"
                            locations={searchData.results}
                            searchLocation={searchData.state.postcode}
                            streetViewControl={true}
                            mapTypeControl={true}
                            fullscreenControl={true}
                            height={500}
                            apiKey={this.options.apiKey}
                        />
                        ,
                        this.refs.map_results_map_container
                    );
                }
                else {

                    // Clear markers from existing map component and then add the new results and update search postcode
                    this.mapComponent.update(searchData.results, searchData.state.postcode);
                }

                // Display the map container
                this.refs.map_results_outer_map_container.classList.remove('hidden');

                if (searchData.hasMoreResults) {

                    render(
                        <button type="button" class="button button--primary" onClickCapture={this.onMoreResultsClick} value="load more" >load more</button>
                        ,
                        this.refs.map_results_footer_container
                    );
                }
            }
            else {

                if (this.mapComponent) {

                    // Clear markers from existing map component
                    this.mapComponent.removeAllLocations();

                    // Hide the map container
                    this.refs.map_results_outer_map_container.classList.add('hidden');
                }

                if (searchData.showNoResultsContent) {

                    const htmlContentObj = { __html: this.refs.no_results_content_location.innerHTML };
                    render(
                        <div class="notifications__item notification-item" dangerouslySetInnerHTML={htmlContentObj} />
                        ,
                        this.refs.map_results_message_container
                    );
                } else {
                    render(
                        <div class="notifications__item notification-item">
                            {searchData.resultsMessage}
                        </div>
                        ,
                        this.refs.map_results_message_container
                    );
                }

                // Display the message container
                this.refs.map_results_message_container.classList.remove('hidden');
            }
        }
        else {
            if (!this.mapComponent) {

                this.displayError();
            }
            else {
                 // Add new results to the map component
                 this.mapComponent.addLocations(searchData.results);
            }

            if (!searchData.hasMoreResults || !searchData.hasResults || !this.mapComponent) {

                this.refs.map_results_footer_container.innerHTML = '';
            }
        }
    }

    renderMainListResults(searchData) {

        if (searchData.clearResults) {

            this.refs.main_list_results_toolbar_container.innerHTML = '';
            this.refs.main_list_results_list_container.innerHTML = '';
            this.refs.main_list_results_footer_container.innerHTML = '';

            if (searchData.hasResults) {

                if (searchData.hasSortOptions) {

                    render(
                        <AsdSortDropdown sortType="sort" sortOptions={searchData.sortOptions} onSort={this.onSortChanged} />
                        ,
                        this.refs.main_list_results_toolbar_container
                    );
                }

                this.renderMainListResultsItems(searchData, this.refs.main_list_results_list_container);

                if (searchData.hasMoreResults) {

                    render(
                        <button type="button" class="button button--primary" onClickCapture={this.onMoreResultsClick} value="text more" >load more</button>
                        ,
                        this.refs.main_list_results_footer_container
                    );
                }
            }
            else {

                if (searchData.showNoResultsContent) {

                    const htmlContent = searchData.state.postcode
                        ? this.refs.no_results_content_location.innerHTML
                        : this.refs.no_results_content_nonlocation.innerHTML;

                    const htmlContentObj = { __html: htmlContent };
                    render(
                        <div class="notifications__item notification-item" dangerouslySetInnerHTML={htmlContentObj} />
                        ,
                        this.refs.main_list_results_list_container
                    );
                } else {
                    render(
                        <div class="notifications__item notification-item">
                            {searchData.resultsMessage}
                        </div>
                        ,
                        this.refs.main_list_results_list_container
                    );
                }
            }
        }
        else {
            if (searchData.hasResults) {

                // Hack to support appending more results
                // This is clearly not the correct way to do this with preact / react but without refactoring
                // to properly use component state this will have to do for now
                const moreContainer = document.createElement('div');

                this.renderMainListResultsItems(searchData, moreContainer);

                this.refs.main_list_results_list_container.appendChild(moreContainer);
            }

            if (!searchData.hasMoreResults || !searchData.hasResults) {

                this.refs.main_list_results_footer_container.innerHTML = '';
            }
        }
    }

    renderMainListResultsItems(searchData, containerElement) {

        render(
            searchData.state.postcode ? (
                searchData.results.map((item) => {

                    const address = item.addressParts && item.addressParts.length ? item.addressParts.join(', ') : '';

                    const distanceText = (item.distanceFromSearchLocation || item.distanceFromSearchLocation === 0) && item.distanceUnit
                        ? `${item.distanceFromSearchLocation} ${item.distanceFromSearchLocation === 1 ? item.distanceUnit.slice(0, -1) : item.distanceUnit} from ` + searchData.state.postcode
                        : '';
                        
                    return(
                        <AsdSearchResultCard
                            pageUrl={item.pageUrl}
                            title={item.title}
                            description={item.description}
                            categories={item.categories}
                            address={address}
                            distanceText={distanceText}
                            latitude={item.latitude}
                            longitude={item.longitude}
                            onViewOnMapClicked={this.onListResultViewOnMapClick}
                        />
                    );
                })
            ) : (
                searchData.results.map((item) => {
                    return (
                        <AsdSearchResultCard
                            pageUrl={item.pageUrl}
                            title={item.title}
                            description={item.description}
                            categories={item.categories}
                        />
                    );
                })
            )
            ,
            containerElement
        );
    }

    renderOnlineResults(searchData) {

        if (searchData.clearResults) {

            this.refs.online_results_toolbar_container.innerHTML = '';
            this.refs.online_results_list_container.innerHTML = '';
            this.refs.online_results_footer_container.innerHTML = '';

            if (searchData.hasResults) {

                if (searchData.hasSortOptions) {

                    render(
                        <AsdSortDropdown sortType="onlineSort" sortOptions={searchData.sortOptions}  onSort={this.onOnlineSortChanged} />
                        ,
                        this.refs.online_results_toolbar_container
                    );
                }

                this.renderOnlineResultsItems(searchData, this.refs.online_results_list_container);

                if (searchData.hasMoreResults) {

                    render(
                        <button type="button" class="button button--primary" onClickCapture={this.onMoreOnlineResultsClick} text="load more">load more</button>
                        ,
                        this.refs.online_results_footer_container
                    );
                }
            }
            else {

                if (searchData.showNoResultsContent) {

                    const htmlContentObj = { __html: this.refs.no_results_content_online.innerHTML };
                    render(
                        <div class="notifications__item notification-item" dangerouslySetInnerHTML={htmlContentObj} />
                        ,
                        this.refs.online_results_list_container
                    );
                } else {
                    render(
                        <div class="notifications__item notification-item">
                            {searchData.resultsMessage}
                        </div>
                        ,
                        this.refs.online_results_list_container
                    );
                }
            }
        }
        else {
            if (searchData.hasResults) {

                // Hack to support appending more results
                // This is clearly not the correct way to do this with preact / react but without refactoring
                // to properly use component state this will have to do for now
                const moreContainer = document.createElement('div');

                this.renderOnlineResultsItems(searchData, moreContainer);

                this.refs.online_results_list_container.appendChild(moreContainer);
            }

            if (!searchData.hasMoreResults || !searchData.hasResults) {

                this.refs.online_results_footer_container.innerHTML = '';
            }
        }
    }

    renderOnlineResultsItems(searchData, containerElement) {

        render(
            searchData.results.map((item) => {
                return (
                    <AsdSearchResultCard
                        pageUrl={item.pageUrl}
                        title={item.title}
                        description={item.description}
                        categories={item.categories}
                    />
                );
            })
            ,
            containerElement
        );
    }
}
