
/* general styles */
import './general/scss/index.scss';

/* stuff and polyfills */
import './general/js/jquery-to-global-scope';
import './general/js/lazysizes';
import './general/js/what-input';
/*import './general/js/hamburger-menu';
import './general/js/top-nav';
import './general/js/calm-toggle';
import './general/js/main-navigation';*/

/* shared */
import 'shared/quantity';
import 'shared/member-discount';
import 'shared/product-card';
import 'shared/modal';
import 'shared/order-summary';
import 'shared/form-steps';
import 'shared/mini-basket';
import 'shared/server-errors';
import 'shared/loqate';
import 'shared/spinner';
import 'shared/notifications';
import 'shared/participant-card';
import 'shared/copy-to-clipboard';
import 'shared/ie11-notification';
import 'shared/google-tag-manager';
import 'shared/nas-form';

/* components */
import 'components/gtm';
import 'components/lazysizes';
import 'components/hero-banner';
import 'components/primary-navigation';
import 'components/global-header';
import 'components/global-footer';
import 'components/owl-carousel';
import 'components/accordion';
import 'components/testimonials';
import 'components/promotional-panel';
import 'components/rich-text-editor';
import 'components/generic-section';
import 'components/forms/azure-sso';
import 'components/forms/form-widget';
import 'components/forms/form-field';
import 'components/text-image-carousel';
import 'components/signposts';
import 'components/map-widget';
import 'components/related-advice';
import './components/tiled-page-list';
import 'components/content-block';
import 'components/blockquotes';
import 'components/related-news';
import 'components/video';
import 'components/help-tip';
import 'components/tabs';
import './components/related-stories';
import './components/breadcrumbs';
import './components/image-gallery';
import './components/pull-out-text';
import './components/contact-info-map';
import './components/audience-selector';
import './components/community-signpost';
import './components/filters';
import './components/search-results';
import './components/utility-navigation';
import './components/configurable-autism-map';
import './components/service-directory';
import './components/explainers';
import './components/benefits-list';
import './components/region-selector';
import './components/press-media';
import './components/step-process';
import './components/featured-news';
import './components/image';
import './components/related-content';
import './components/text-truncation';
import './components/twitter-feed';
import './components/facebook-feed';
import './components/social-settings';
import './components/search-widget';
import './components/fundraiser-totaliser';
import './components/recite-me-toolbar';
import './components/instagram-feed';
import './components/chat-bot';
import './components/service-directory-search-results-page';

import './components/product-page';
import './components/read-more';
import './components/event-page';
import './components/story-page';
import 'components/added-to-basket-modal';
import './components/guidance-page';
import './components/news-page';
import './components/file-page';
import 'components/featured-products';
import 'components/membership-form';
import 'components/signin-modal';
import 'components/account';
import 'components/account-details-form';
import 'components/account-preferences-form';
import 'components/account-orders';
import 'components/account-events';
import 'components/account-selector';
import 'components/sign-up';
import 'components/dimmer-switch';
import 'components/utility-navigation';
import 'components/notification-bell';
import 'components/profile-dropdown';
import 'components/cookie-bar';
import 'components/cookie-settings';
import 'components/participant-registration';
import 'components/service-editor';
import 'components/service-page';
/* general */
import 'general/js/cookie-buttons-initiator';

/* init app */
import app from './general/js/app';

app.init();

/* require svg */
const files = require.context('./general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);

// do not focus sprite in IE
const spriteNode = document.getElementById('__SVG_SPRITE_NODE__');

if (spriteNode) {
    spriteNode.setAttribute('focusable', 'false');
    spriteNode.setAttribute('aria-hidden', 'true');
}
